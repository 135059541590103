import { ChangelogItem } from '../interfaces/changelog-item';

export const changelogData: ChangelogItem[] = [
	{
		changeName: 'Revise the data table page to incorporate the updated border specifications.',
		changeDate: new Date('2024-11-04 00:00:00'),
		workId: 'SDS-3650',
		sectionName: 'Data Table Component',
	},
	{
		changeName: 'Revise the data grid page to incorporate the updated border specifications.',
		changeDate: new Date('2024-11-04 00:00:00'),
		workId: 'SDS-3650',
		sectionName: 'Data Grid Component',
	},
	{
		changeName: 'Added badges styles to type ramp, color & contrast, and where is it used? sections.',
		changeDate: new Date('2024-10-22 00:00:00'),
		workId: 'SDS-3559',
		sectionName: 'Typography Guidelines',
	},
	{
		changeName: 'Added badge page',
		changeDate: new Date('2024-10-22 00:00:00'),
		workId: 'SDS-3559',
		sectionName: 'Badge Component',
	},
	{
		changeName: 'Added data table page',
		changeDate: new Date('2024-09-24 00:00:00'),
		workId: 'SDS-3100',
		sectionName: 'Data Table Component',
	},
	{
		changeName: 'Added slider page',
		changeDate: new Date('2024-09-24 00:00:00'),
		workId: 'SDS-3140',
		sectionName: 'Slider Component',
	},
	{
		changeName: 'Added password page',
		changeDate: new Date('2024-09-24 00:00:00'),
		workId: 'SDS-3101',
		sectionName: 'Password Field Component',
	},
	{
		changeName: 'Added pagination page',
		changeDate: new Date('2024-09-24 00:00:00'),
		workId: 'SDS-2749',
		sectionName: 'Pagination Component',
	},
	{
		changeName: 'Added vertical variation to page',
		changeDate: new Date('2024-09-24 00:00:00'),
		workId: 'SDS-702',
		sectionName: 'Tab Component',
	},
	{
		changeName: 'Added borderless accordion to page',
		changeDate: new Date('2024-09-24 00:00:00'),
		workId: 'SDS-3161',
		sectionName: 'Accordion Component',
	},
	{
	 	changeName: 'Added line chart page',
	 	changeDate: new Date('2024-09-24 00:00:00'),
	 	workId: 'SDS-3064',
	 	sectionName: 'Line Chart Component',
	 },
	{
		changeName: 'Added motion guidelines',
		changeDate: new Date('2024-05-28 00:00:00'),
		workId: 'SDS-2764',
		sectionName: 'Motion Guidelines',
	},
	{
		changeName: 'Added number field live example',
		changeDate: new Date('2024-05-28 00:00:00'),
		workId: 'SDS-909',
		sectionName: 'Number Field Component',
	},
	{
		changeName: 'Added tooltip live example',
		changeDate: new Date('2024-05-28 00:00:00'),
		workId: 'SDS-138',
		sectionName: 'Tooltip Component',
	},
	{
		changeName: 'Added breadcrumb guidance',
		changeDate: new Date('2024-05-02 00:00:00'),
		workId: 'SDS-834',
		sectionName: 'Breadcrumb Component',
	},
	{
		changeName: 'Added breadcrumb live example',
		changeDate: new Date('2024-05-02 00:00:00'),
		workId: 'SDS-835',
		sectionName: 'Breadcrumb Component',
	},
	{
		changeName: 'Added drop-down guidance',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-2715',
		sectionName: 'Drop-down Component',
	},
	{
		changeName: 'Added number field guidance',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-2789',
		sectionName: 'Number Field Component',
	},
	{
		changeName: 'Added help text live example',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-678',
		sectionName: 'Help Text Component',
	},
	{
		changeName: 'Added modal live example',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-715',
		sectionName: 'Modal Component',
	},
	{
		changeName: 'Added typography color ramp',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-2564',
		sectionName: 'Typography Guidelines',
	},
	{
		changeName: 'Added accordion guidance',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-2789',
		sectionName: 'Accordion Component',
	},
	{
		changeName: 'Added accordion live example',
		changeDate: new Date('2024-04-25 00:00:00'),
		workId: 'SDS-729',
		sectionName: 'Accordion Component',
	},
	{
		changeName: 'Added help text guidance',
		changeDate: new Date('2024-04-02 00:00:00'),
		workId: 'SDS-679',
		sectionName: 'Help Text Component',
	},
	{
		changeName: 'Added tooltip guidance',
		changeDate: new Date('2024-04-02 00:00:00'),
		workId: 'SDS-695',
		sectionName: 'Tooltip Component',
	},
	{
		changeName: 'Added switch guidance',
		changeDate: new Date('2024-04-02 00:00:00'),
		workId: 'SDS-724',
		sectionName: 'Switch Component',
	},
	{
		changeName: 'Added switch live example',
		changeDate: new Date('2024-04-02 00:00:00'),
		workId: 'SDS-907',
		sectionName: 'Switch Component',
	},
	{
		changeName: 'Added modal guidance',
		changeDate: new Date('2024-04-02 00:00:00'),
		workId: 'SDS-716',
		sectionName: 'Modal Component',
	},
	{
		changeName: 'Added typography guidelines',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-2219',
		sectionName: 'Typography Guidelines',
	},
	{
		changeName: 'Added date picker guidance',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-1884',
		sectionName: 'Date Picker Component',
	},
	{
		changeName: 'Added date picker live example',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-915',
		sectionName: 'Date Picker Component',
	},
	{
		changeName: 'Added progress indicator guidance',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-699',
		sectionName: 'Progress Indicator Component',
	},
	{
		changeName: 'Added progress indicator live example',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-698',
		sectionName: 'Progress Indicator Component',
	},
	{
		changeName: 'Added time picker guidance',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-674',
		sectionName: 'Time Picker Component',
	},
	{
		changeName: 'Added time picker live example',
		changeDate: new Date('2024-03-11 00:00:00'),
		workId: 'SDS-920',
		sectionName: 'Time Picker Component',
	},
	{
		changeName: 'Added bar chart live example',
		changeDate: new Date('2024-01-25 00:00:00'),
		workId: 'SDS-911',
		sectionName: 'Bar Chart Component',
	},
	{
		changeName: 'Added button group guidance',
		changeDate: new Date('2024-01-25 00:00:00'),
		workId: 'SDS-668',
		sectionName: 'Button Group Component',
	},
	{
		changeName: 'Added button group live example',
		changeDate: new Date('2024-01-25 00:00:00'),
		workId: 'SDS-667',
		sectionName: 'Button Group Component',
	},
	{
		changeName: 'Added button group redlines content examples',
		changeDate: new Date('2024-01-25 00:00:00'),
		workId: 'SDS-1790',
		sectionName: 'Button Group Component',
	},
	{
		changeName: 'Added toast guidance',
		changeDate: new Date('2024-01-25 00:00:00'),
		workId: 'SDS-696',
		sectionName: 'Toast Component',
	},
	{
		changeName: 'Added toast live example',
		changeDate: new Date('2024-01-25 00:00:00'),
		workId: 'SDS-910',
		sectionName: 'Toast Component',
	},
	{
		changeName: 'Added checkbox guidance',
		changeDate: new Date('2023-11-23 00:00:00'),
		workId: 'SDS-718',
		sectionName: 'Checkbox Component',
	},
	{
		changeName: 'Added checkbox live example',
		changeDate: new Date('2023-11-23 00:00:00'),
		workId: 'SDS-906',
		sectionName: 'Checkbox Component',
	},
	{
		changeName: 'Added label / value pair guidance',
		changeDate: new Date('2023-11-23 00:00:00'),
		workId: 'SDS-665',
		sectionName: 'Label / Value Pair Component',
	},
	{
		changeName: 'Added label / value pair live example',
		changeDate: new Date('2023-11-23 00:00:00'),
		workId: 'SDS-922',
		sectionName: 'Label / Value Pair Component',
	},
	{
		changeName: 'Added Shield FAQ',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1523',
		sectionName: 'Shield FAQ',
	},
	{
		changeName: 'Added advanced filter guidance',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1598',
		sectionName: 'Filter Pattern',
	},
	{
		changeName: 'Added advanced filter live example',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1450',
		sectionName: 'Filter Pattern',
	},
	{
		changeName: 'Added accessibility guidelines',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1581',
		sectionName: 'Accessibility Guidelines',
	},
	{
		changeName: 'Added text field guidance',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-726',
		sectionName: 'Text Field Component',
	},
	{
		changeName: 'Added text field live example',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-913',
		sectionName: 'Text Field Component',
	},
	{
		changeName: 'Added BeyondTrust icon to icon library',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1592',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added Elastic icon to icon library',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1545',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added AWS icon to icon library',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1582',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added Azure icon to icon library',
		changeDate: new Date('2023-10-26 00:00:00'),
		workId: 'SDS-1582',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added tag guidance',
		changeDate: new Date('2023-09-28 00:00:00'),
		workId: 'SDS-693',
		sectionName: 'Tag Component',
	},
	{
		changeName: 'Added tag live example',
		changeDate: new Date('2023-09-28 00:00:00'),
		workId: 'SDS-918',
		sectionName: 'Tag Component',
	},
	{
		changeName: 'Added spacing guidelines',
		changeDate: new Date('2023-09-28 00:00:00'),
		workId: 'SDS-1293',
		sectionName: 'Spacing Guidelines',
	},
	{
		changeName: 'Added filter guidance',
		changeDate: new Date('2023-09-14 00:00:00'),
		workId: 'SDS-125',
		sectionName: 'Filter Pattern',
	},
	{
		changeName: 'Added filter live example',
		changeDate: new Date('2023-09-14 00:00:00'),
		workId: 'SDS-902',
		sectionName: 'Filter Pattern',
	},
	{
		changeName: 'Added tab guidance',
		changeDate: new Date('2023-09-14 00:00:00'),
		workId: 'SDS-697',
		sectionName: 'Tab Component',
	},
	{
		changeName: 'Added tab live example',
		changeDate: new Date('2023-09-14 00:00:00'),
		workId: 'SDS-919',
		sectionName: 'Tab Component',
	},
	{
		changeName: 'Added Figma links to icons',
		changeDate: new Date('2023-09-14 00:00:00'),
		workId: 'SDS-1465',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added radio button redlines content examples',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-1109',
		sectionName: 'Radio Button Component',
	},
	{
		changeName: 'Added button redlines content examples',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-1105',
		sectionName: 'Button Component',
	},
	{
		changeName: 'Added menu guidance',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-687',
		sectionName: 'Menu Component',
	},
	{
		changeName: 'Added menu live example',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-686',
		sectionName: 'Menu Component',
	},
	{
		changeName: 'Added text link guidance',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-694',
		sectionName: 'Text Link Component',
	},
	{
		changeName: 'Added text link live example',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-917',
		sectionName: 'Text Link Component',
	},
	{
		changeName: 'Added severity guidelines',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-735',
		sectionName: 'Severity Guidelines',
	},
	{
		changeName: 'Added severity component guidance',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-1015',
		sectionName: 'Severity Component',
	},
	{
		changeName: 'Added severity component live example',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-1103',
		sectionName: 'Severity Component',
	},
	{
		changeName: 'Added icon component guidance',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-675',
		sectionName: 'Icon Component',
	},
	{
		changeName: 'Added icon component live example',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-904',
		sectionName: 'Icon Component',
	},
	{
		changeName: 'Added icon guidelines',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-1130',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added icon library display',
		changeDate: new Date('2023-08-31 00:00:00'),
		workId: 'SDS-1131',
		sectionName: 'Icon Guidelines',
	},
	{
		changeName: 'Added data visualization guidelines',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-959',
		sectionName: 'Data Visualization Guidelines',
	},
	{
		changeName: 'Added formatting guidelines',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-736',
		sectionName: 'Formatting Guidelines',
	},
	{
		changeName: 'Added content guidelines',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-960',
		sectionName: 'Content Guidelines',
	},
	{
		changeName: 'Added pie chart guidance',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-683',
		sectionName: 'Pie Chart Component',
	},
	{
		changeName: 'Added pie chart live example',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-927',
		sectionName: 'Pie Chart Component',
	},
	{
		changeName: 'Added donut chart guidance',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-680',
		sectionName: 'Donut Chart Component',
	},
	{
		changeName: 'Added donut chart live example',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-926',
		sectionName: 'Donut Chart Component',
	},
	{
		changeName: 'Added button guidance',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-688',
		sectionName: 'Button Component',
	},
	{
		changeName: 'Added button live example',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-908',
		sectionName: 'Button Component',
	},
	{
		changeName: 'Added radio button guidance',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-690',
		sectionName: 'Radio Button Component',
	},
	{
		changeName: 'Added radio button live example',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-905',
		sectionName: 'Radio Button Component',
	},
	{
		changeName: 'Added changelog',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-961',
		sectionName: 'Changelog',
	},
	{
		changeName: 'Updated typography for data grids',
		changeDate: new Date('2023-08-03 00:00:00'),
		workId: 'SDS-790',
		sectionName: 'Data Grid Component',
	},
	{
		changeName: 'Combined ZeroHeight 3.0, 3.1, and Azure DevOps content',
		changeDate: new Date('2023-06-07 00:00:00'),
		workId: '',
		sectionName: 'All Sections',
	},
	{
		changeName: 'Added Kendo content to data grids',
		changeDate: new Date('2023-06-07 00:00:00'),
		workId: 'SDS-739',
		sectionName: 'Data Grid Component',
	},
];
